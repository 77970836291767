CW = window.CW || {};
CW.testApp = document.getElementById('ursus-basket');

CW.basket = {
    url: '/ajax/basket/',
    basketPull: {},
    basketTimeout: false,
    basketTime: 500,
    BasketUpdateEvents: {},
    Basket: [],
    LABEL: 0,
    storageKey: 'CW.BASKET',
    storageLabel: 'CW.BASKETLABEL',

    checkBasket: function (productId) {
        if (this.Basket) {
            return CW.getFromArrayByValue(this.Basket, 'PRODUCT_ID', productId);
        }
        return false;
    },

    syncBasket: function (cart) {
        if (this.basketTimeout) {
            clearTimeout(this.basketTimeout);
            this.basketTimeout = false;
        }
        let self = this;
        cart.forEach(function (item) {
            self.basketPull['fix' + item.id] = {
                id: item.id,
                quantity: item.quantity
            };
        });
        this.basketTimeout = setTimeout(function () {
            self._syncBasket.call(self);
        }, this.basketTime);
    },
    _syncBasket: function () {
        let cart = [];
        for (var key in this.basketPull) {
            if (this.basketPull.hasOwnProperty(key)) {
                let item = this.basketPull[key];
                cart.push(item);
            }
        }
        let self = this;
        $.ajax({
            url: this.url,
            global: false,
            type: "POST",
            data: ({
                action: 'sync',
                basket: JSON.stringify(cart)
            }),
            success: function (res) {
                self.setBasket(res);

                if(!window.location.href.includes('basket')) {
                    $('.js-basket-popup').addClass('open');

                    setTimeout(function() {
                        const notePopup = document.querySelectorAll('.js-basket-popup');
                        if (notePopup.length) {
                            notePopup.forEach(popup => {
                                popup.classList.remove('open');
                            })
                        }
                    }, 3000);
                }

                if($('.icon-header-item.cart .count').hasClass('show')) {
                    $('.header-mob-panel__item-basket .count').addClass('show');
                    $('.header-mob-panel__item-basket .count').removeClass('hide');
                } else {
                    $('.header-mob-panel__item-basket .count').removeClass('show');
                    $('.header-mob-panel__item-basket .count').addClass('hide');
                }
                if($('.header-mob-panel__item-basket .count')[0]) {
                    $('.header-mob-panel__item-basket .count')[0].innerHTML = $('.icon-header-item.cart .count')[0].textContent
                }
                // после добавления товара в корзину происходит рассчет новых цен с учётом объёма и скидки. Новые цены получаем по queryUrl и подставляем в каталог/детальную стр
                const queryUrl = window.location.href.split('#')[0] + '?isAjax=true&viewMode=jsonPrice';

                // если мы на странице корзины - перерасчет не производим
                if(queryUrl.includes('basket')) return

                $.ajax({
                    url: queryUrl,
                    global: false,
                    type: "POST",
                    success: function(data) {
                        console.log('responsed data is', data)
                        const responseData = JSON.parse(data);

                        // если JSON распарсился в массив (для страницы каталога)
                        if(responseData.length) {
                            responseData.forEach(item => {
                                // меняем цену товара на пересчитанную с учетом объёма и скидки
                                const curId = item.id;
                                const newPrice = +item.price;
                                $(`[product-id-price="product-${curId}"]`).text(newPrice.toLocaleString() + ' ₽');

                                // меняем цены торговых предложений, если они есть
                                const offers = item.offers;

                                if(offers) {
                                    offers.forEach(offer => {
                                        if(offer.price) {
                                            const offerId = offer.id;
                                            const offerNewPrice = +offer.price;
                                            $(`[product-id-price="product-${offerId}"]`).text(offerNewPrice.toLocaleString() + ' ₽');
                                        }
                                    })
                                }
                            })

                            // пересчитываем "итого" для таблицы размеров товара в каталоге с учетом новых цен
                            let customEvent = new Event('recalculateEvent');
                            const sizesTables = document.querySelectorAll('.js-table-size');

                            if(sizesTables.length > 0) {
                                sizesTables.forEach(table => {
                                    table.dispatchEvent(customEvent);
                                })
                            }
                        }

                        // если JSON распарсился как объект (для детальной стр)
                        else {
                            const curId = responseData.id;
                            const newPrice = +responseData.price;
                            const b2cDetailFinalPriceElem = $('.info-product-wr .price-wr--with-lock-btn .price-block .price');

                            // для детальной страницы с табличным представленим размеров (b2b-вида)
                            if($(`[product-id-price="product-${curId}"]`).length !== 0) {
                                $(`[product-id-price="product-${curId}"]`).text(newPrice.toLocaleString() + ' ₽');
                                // меняем цены торговых предложений, если они есть
                                const offers = responseData.offers;
                                if(offers) {
                                    offers.forEach(offer => {
                                        if(offer.price) {
                                            const offerId = offer.id;
                                            const offerNewPrice = +offer.price;
                                            $(`[product-id-price="product-${offerId}"]`).text(offerNewPrice.toLocaleString() + ' ₽');
                                        }
                                    })
                                }
                            }

                            // для детальной вида b2c с представлением цен в виде плитки
                            else if(b2cDetailFinalPriceElem) {
                                b2cDetailFinalPriceElem.text(newPrice.toLocaleString() + ' ₽');

                                const offers = responseData.offers;
                                if(offers) {
                                    offers.forEach(offer => {
                                        if(offer.price) {
                                            const offerId = offer.id;
                                            const offerNewPrice = +offer.price;
                                            $(`[data-id="${offerId}"]`).attr('data-format-price', offerNewPrice);
                                        }
                                    })
                                }
                            }
                        }
                    }
                })
            },
            error: function (a, b, c, d) {
            }
        });
        clearTimeout(this.basketTimeout);
        this.basketTimeout = false;
        this.basketPull = {};
    },

    regBasketUpdateEvent: function (id, fn) {
        if (id === undefined || typeof (fn) !== 'function')
            return false;
        this.BasketUpdateEvents[id] = fn;
        return true;
    },
    runBasketUpdateEvent: function (res) {
        for (var key in this.BasketUpdateEvents) {
            if (this.BasketUpdateEvents.hasOwnProperty(key) && this.BasketUpdateEvents[key]) {
                try {
                    let result;
                    result = this.BasketUpdateEvents[key](res);
                    if (result === false) {//для одноразовых обработчиков
                        this.BasketUpdateEvents[key] = false;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
    },
    setBasket: function (res, alreadyFromStorage) {
        if (res) {
            this.LABEL = res.TIME + ':' + res.COUNT;
            this.Basket = res.PRODUCTS;

            if (alreadyFromStorage !== true) {
                let storageBasket = Object.assign({}, res);
                storageBasket.LOG = [];
                localStorage.setItem(this.storageKey, JSON.stringify(storageBasket));
                localStorage.setItem(this.storageLabel, this.LABEL);
            }

            this.runBasketUpdateEvent(res);
                    if($('.icon-header-item.favorite .count')[0].textContent > 0) {
                        $('.header-mob-panel__item[href="/favorites/"] .count').removeClass('hide');
                        $('.header-mob-panel__item[href="/favorites/"] .count').addClass('show');
                    }
                    if($('.icon-header-item.favorite .count').hasClass('hide')) {
                        $('.header-mob-panel__item[href="/favorites/"] .count').addClass('hide');
                        $('.header-mob-panel__item[href="/favorites/"] .count').removeClass('show');
                    }
                    if($('.header-mob-panel__item[href="/favorites/"] .count')[0]) {
                        $('.header-mob-panel__item[href="/favorites/"] .count')[0].innerHTML = $('.icon-header-item.favorite .count')[0].textContent
                    }
        }
    },
    syncProduct: function (id, quantity) {
        id = parseInt(id);
        quantity = parseFloat(quantity);

        var cart = [];
        cart.push({id: id, quantity: quantity});
        return this.syncBasket(cart);
    },
    forceBasketPull: function () {
        let self = this;
        if (self.basketPull.length)
            $.ajax({
                url: this.url,
                global: false,
                type: "POST",
                data: ({
                    action: 'sync',
                    basket: JSON.stringify(this.basketPull)
                }),
                success: function () {

                    if(!window.location.href.includes('basket')) {
                        $('.js-basket-popup').addClass('open');

                        setTimeout(function() {
                            const notePopup = document.querySelectorAll('.js-basket-popup');
                            if (notePopup.length) {
                                notePopup.forEach(popup => {
                                    popup.classList.remove('open');
                                })
                            }
                        }, 3000);
                    }

                    if (this.basketTimeout) {
                        clearTimeout(this.basketTimeout);
                        this.basketTimeout = false;
                    }
                    self.basketPull = {};
                }
            });
    },
    checkLocalStorage: function () {
        let onStorageLabel = localStorage.getItem(this.storageLabel);
        if (onStorageLabel && (this.LABEL != onStorageLabel)) {
            let onStorage = localStorage.getItem(this.storageKey);
            let storageBasket = JSON.parse(onStorage);
            if (storageBasket) {
                this.setBasket(storageBasket, true);
            }
        }
    },
};


$(function () {
    setInterval(function () {
        CW.basket.checkLocalStorage.call(CW.basket);
    }, 311 + CW.getRandomInt(0, 99));
});

window.onbeforeunload = function () {
    CW.basket.forceBasketPull.call(CW.basket);
};

CW.basket.regBasketUpdateEvent('errorProvider', function (res) {
    if (res && res.LOG && res.LOG.length) {

        var error = "";
        res.LOG.forEach(function (item, i, arr) {
            if (item.error) {
                error += "<br/>" + item.error;
            }
        });

        if (res.ERR_UF) {
            error = error + '<br/><span class="alert-red">Данное ограничение не действует при авторизации как Юр.лицо/ИП.</span>';
        }

        if (error) {
            alert(error);
            // console.error(res.LOG);
        } else {
            // console.log(res.LOG);
        }
    }
});

CW.basket.regBasketUpdateEvent('successProvider', function (res) {
    const windowWidth = window.innerWidth;
    // if (res) {
    //     let toShowPopupAfterAction = true;
    //     console.log('toShowPopupAfterAction', toShowPopupAfterAction);
    //
    //     if(window.location.href.includes('basket')) {
    //         toShowPopupAfterAction = false;
    //     }
    //
    //     $('.js-basket-popup').addClass('open');
    //
    //     setTimeout(function() {
    //         const notePopup = document.querySelector('.js-basket-popup');
    //         if (notePopup) {
    //             notePopup.classList.remove('open');
    //         }
    //     }, 3000);
    //
    // }

    if (res && res.LOG && res.LOG.length) {
        var addedItems = [];
        var updatedItems = [];
        let toShowPopupAfterAction = true

        res.LOG.forEach(function (item, i, arr) {
            if (item.action && (item.action ==='add') && !item.error) {
                addedItems.push(item);
            }

            // если на странице товара изменили количество уже добавленного ранее в корзину товара
            if (item.action && (item.action ==='update') && !item.error) {
                updatedItems.push(item);
            }

            if(item.action ==='remove') {
                toShowPopupAfterAction = false;
            }
        });

        // если добавляем товар через корзину - не показываем модальное окно
        if(window.location.href.includes('basket')) {
            toShowPopupAfterAction = false;
        }

        // на десктопе не показываем подробное модальное окно, а показываем сокращенный вариант
        if(windowWidth > 1024) {
            toShowPopupAfterAction = false;
            $('.js-basket-popup').addClass('open');

            setTimeout(function() {
                const notePopup = document.querySelector('.js-basket-popup');
                if (notePopup) {
                    notePopup.classList.remove('open');
                }
            }, 3000);
        }

        var _cont = $("#basket-added-popup_container");
        var _itm = _cont.find(".basket-added-popup_items .basket-added-popup_item").eq(0).clone();
        var _itmsCnt = _cont.find(".basket-added-popup_items");

        _itmsCnt.html('');

        // console.log('addedItems',addedItems);

        for (var i in addedItems) {
            _itmJ = addedItems[i];
            //console.log('_itmJ',_itmJ);
            _itmLoc = _itm.clone();
            _itmJ.el.pic ? _itmLoc.find('.basket-added-popup_picture img').attr('src',_itmJ.el.pic).show() : _itmLoc.find('.basket-added-popup_picture img').removeAttr('src').hide();
            _itmLoc.find('.basket-added-popup_desc .basket-added-popup_item-title').html(_itmJ.el.NAME);
            _opts = '';

            if (_itmJ.el.PROPERTY_ROST_VALUE) {
                _opts += '<tr><td>Рост</td><td>' + _itmJ.el.PROPERTY_ROST_VALUE + '</td>';
            }

            if (_itmJ.el.PROPERTY_RAZMER_VALUE) {
                _opts += '<tr><td>Размер</td><td>' + _itmJ.el.PROPERTY_RAZMER_VALUE + '</td>';
            }

            if (_opts) {
                _opts = "<table>" + _opts + "</table>";
            }

            _itmLoc.find('.basket-added-popup_desc .basket-added-popup_item-options').html(_opts);
            _itmLoc.find('.basket-added-popup_summ').html(_itmJ.quantity + '&nbsp;x&nbsp;' + _itmJ.aprice);

            _itmsCnt.append(_itmLoc);
        }

        for (var i in updatedItems) {
            _itmJ = updatedItems[i];
            //console.log('_itmJ',_itmJ);
            _itmLoc = _itm.clone();
            _itmJ.el.pic ? _itmLoc.find('.basket-added-popup_picture img').attr('src',_itmJ.el.pic).show() : _itmLoc.find('.basket-added-popup_picture img').removeAttr('src').hide();
            _itmLoc.find('.basket-added-popup_desc .basket-added-popup_item-title').html(_itmJ.el.NAME);
            _opts = '';

            if (_itmJ.el.PROPERTY_ROST_VALUE) {
                _opts += '<tr><td>Рост</td><td>' + _itmJ.el.PROPERTY_ROST_VALUE + '</td>';
            }

            if (_itmJ.el.PROPERTY_RAZMER_VALUE) {
                _opts += '<tr><td>Размер</td><td>' + _itmJ.el.PROPERTY_RAZMER_VALUE + '</td>';
            }

            if (_opts) {
                _opts = "<table>" + _opts + "</table>";
            }

            _itmLoc.find('.basket-added-popup_desc .basket-added-popup_item-options').html(_opts);
            _itmLoc.find('.basket-added-popup_summ').html(_itmJ.quantity + '&nbsp;x&nbsp;' + _itmJ.aprice);

            _itmsCnt.append(_itmLoc);
        }


        _cont.find('.basket-added-popup_basket-total .basket-added-popup_total-items').html('В корзине '+res.COUNT+' товара(ов)');
        _cont.find('.basket-added-popup_basket-total .basket-added-popup_total-summ').html('Подытог корзины: '+res.С_SUMM);

        // если item.action было не было удалением товара из корзины, то показываем окно
        if(toShowPopupAfterAction) {
            $(".basket-added-popup").addClass('opened');
            $('.shadow-bg').fadeIn(300);
        }
    }
});

CW.basket.regBasketUpdateEvent('dataLayerEcommerce', function (res) {
    if (res && res.LOG && res.LOG.length) {
        window.dataLayer = window.dataLayer || [];

        res.LOG.forEach(function (item, i, arr) {
            var $diff = 0;
            if (item.action === 'add') {
                $diff = item.quantity;
            } else if (item.action === 'delete') {
                $diff = 0 - item.old_quantity;
            } else if (item.action === 'update') {
                $diff = item.quantity - item.old_quantity;
            }

            if ($diff > 0) {//добавление
                dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [{
                                'name': item.name,
                                'id': item.id,
                                'price': parseInt(item.price),
                                'quantity': $diff
                            }]
                        },
                    }
                });

                try { rrApi.addToBasket(item.id) } catch(e) {}
            }
            if ($diff < 0) {//удаление
                dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'remove': {
                            'products': [{
                                'name': item.name,
                                'id': item.id,
                                'price': parseInt(item.price),
                                'quantity': 0 - $diff
                            }]
                        },
                    }
                });
            }
        });
    }
});

$(document).ready(
    function()
    {
        let Sticky = require('sticky-js');
        let stickyTotalBasket = new Sticky(
            '.total-basket-block-inner',
            {
                stickyFor: 1699,
                marginTop: 70,
                marginBottom: 0,
                stickyClass: 'js-sticky'
            }
        );
    }
)

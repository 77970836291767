export default class HiddenText {
    constructor({selector}) {
        if (!selector) return

        this.wrapper = selector;
        this.btn = this.wrapper.querySelector('.js-hidden-text-content-btn');
        this.btnText = this.btn.querySelector('span');
        this.content = this.wrapper.querySelector('.js-hidden-text-content');

        this.eventListeners();
    }

    eventListeners() {
        this.btn.addEventListener('click', (e)=> {
            e.preventDefault();
            if(this.content.classList.contains('hidden')) {
                this.showMore();
            } else {
                this.hideMore();
            }
        })
    }

    showMore() {
        this.content.classList.remove('hidden');
        this.btnText.textContent = "Свернуть";
        this.btn.classList.add('open');
    }

    hideMore() {
        this.content.classList.add('hidden');
        this.btnText.textContent = "Подробнее";
        this.btn.classList.remove('open');
    }
}